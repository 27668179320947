import { FC, ReactNode } from "react";
import "./Heading1.scss";

interface Heading1Props {
  children: ReactNode;
  color?: "default" | "primary" | "danger" | "white";
  align?: "left" | "center";
  className?: string;
}

export const Heading1: FC<Heading1Props> = ({
  children,
  color = "default",
  align = "left",
  className: _className
}) => {
  let className = _className + " heading1";
  switch (color) {
    case "primary":
      className += " heading1--primary";
      break;
    case "danger":
      className += " heading1--danger";
      break;
    case "white":
      className += " heading1--white";
      break;
  }

  if (align === "center") {
    className += " heading1--center";
  }

  return <h1 className={className}>{children}</h1>;
};
