import React from "react";
import "./Why.scss";
import { Heading1 } from "../../UI/Heading1/Heading1";
import { Paragraph } from "../../UI/Paragraph/Paragraph";
import { Heading2 } from "../../UI/Heading2/Heading2";
import masks from '../../assets/masks.png';

interface WhyProps {

}

export const Why: React.FC<WhyProps> = () => {
    return <div className="Why">
        <Heading1 className="Why__heading1">Proč celý ten úvod...?</Heading1>
        <Paragraph className="Why__paragraph">
            Zrovna před pár minutami jsem měl možnost nahlédnout do jednoho materiálu jedné české firmy zaměřené na “inovaci” a “rozrůstání” firem...
        </Paragraph>
        <Heading2 className="Why__heading2">Tragikomedie.</Heading2>
        <img alt="Theater Masks" className="Why__picture" src={masks}></img>
        <Paragraph className="Why__paragraph"><b>Tomuto materiálu nešlo než se <i>smát, kroutit hlavou, šklebit se s otevřenou pusou a brečet</i>. Zaráz.</b></Paragraph>
        <Heading2 className="Why__heading2">Jak může firma, co podporuje své klienty v růstu, dodat materiál, který jejich <span className="underline secondary">klientovi prostě nepomůže?</span></Heading2>
        <Heading2 className="Why__heading2">KTERÝ JE ÚPLNĚ ZBYTEČNÝ?</Heading2>
        <Paragraph>Materiál byl klasický příklad něčeho, co je pěkně zpracované vizuálně, ale nikomu to nepomáhá! Jen těmto “pomocníkům” zbohatnout...</Paragraph>
        <Paragraph>Souvislost s marketingem tu je taková, že <span className="secondary">šlo o průzkum trhu</span>, z něhož měly marketingové a růstové snahy vycházet.</Paragraph>
        <Paragraph>Firma, co ho vytvořila, prostě chtěla <span className="secondary">maximum peněz za minimum práce...</span> Což chápu, tak do jisté míry kapitalizmus funguje...</Paragraph>
        <Heading2 className="Why__heading2">ALE TÍM SE NEOSPRAVEDLŇUJE UPŘÍMNÝ NEZÁJEM O HODNOTU PŘINESENOU JEJICH KLIENTOVI!</Heading2>
    </div>
}