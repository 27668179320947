import { FC, MouseEventHandler } from "react";
import { createPortal } from "react-dom";
import "./Popup.scss";

interface PopupProps {
  onDismiss: () => void;
  text: string;
  isLocked?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
}

export const Popup: FC<PopupProps> = ({
  text,
  onDismiss,
  isLocked = false,
  isError = false,
  isSuccess = false,
}) => {
  const popupNode = document.getElementById("popup") as HTMLElement;

  const stopPropagation: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  const onDismissClick = () => {
    if (isLocked) return;
    onDismiss();
  };

  let className = "popup";
  if (isError) {
    className += " popup--error";
  }
  if (isSuccess) {
    className += " popup--success";
  }

  const popupJsx = (
    <div className={className} onClick={onDismissClick}>
      <div className="popup__content" onClick={stopPropagation}>
        {text}
      </div>
    </div>
  );

  return createPortal(popupJsx, popupNode);
};

export default Popup;
