import { useMediaQuery } from "react-responsive";
import React from 'react';

import Projects from '../../components/Projects/Projects';
import './MainProjects.scss';
import { projectDefinitions } from "../../constants";
import { Heading1 } from "../../UI/Heading1/Heading1";

export const MainProjects: React.FC<{}> = () => {
  let columnCount = 3;
  const isUnder1100 = useMediaQuery({ maxWidth: 1200 });
  const isUnder800 = useMediaQuery({ maxWidth: 800 });
  if (isUnder1100) {
    columnCount = 2;
  }
  if (isUnder800) {
    columnCount = 1;
  }

  return <div className="MainProjects">
      <Heading1>TI, KTEŘÍ NÁM <span className="primary">DŮVĚŘUJÍ</span> A KTERÉ JSME MĚLI MOŽNOST PODPOŘIT:</Heading1>
      <Projects columnCount={columnCount} projectDefinitions={projectDefinitions}></Projects>
    </div>
};

export default MainProjects;