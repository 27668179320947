import './Introduction.scss';

import { Heading2 } from "../../UI/Heading2/Heading2";
import { Paragraph } from '../../UI/Paragraph/Paragraph';

interface IntroductionProps {

}

export const Introduction: React.FC<IntroductionProps> = () => {
    return <div className="Introduction">
        <Paragraph>Dobrý den, ahoj.</Paragraph>
        <Paragraph>Zrovna pracuji se svým týmem na jednom klientově projektu.</Paragraph>
        <Paragraph>Děláme marketing, ale ne tak docela - děláme <span className='underline'><i>růstový</i> marketing</span>.</Paragraph>
        <Heading2 className='Introduction__heading2'>To znamená: nejde nám jen o získání klientů pro naše klienty. Nejde nám jen o zvýšení prodejů</Heading2>
        <Heading2 className='Introduction__heading2'>Jde nám o to, aby firmy našich klientů ROSTLY.</Heading2>
        <Paragraph className='Introduction__paragraph'>Např. neříkáme klientům, jak mají dělat reklamu, když zatím žádnou reklamu nepotřebují!</Paragraph>
    </div>
}

export default Introduction;