import { FC, useCallback, useEffect, useState } from "react";
import "./FixedAction.scss";
import { CTA } from "../CTA/CTA";

interface FixedActionProps {
  offerOffset: number | null;
}

export const FixedAction: FC<FixedActionProps> = ({ offerOffset }) => {
  const [showAction, setShowAction] = useState(true);
  const [currentOffset, setCurrentOffset] = useState<number | null>(null);
  const [scrollToOfferIntent, setScrollToOfferIntent] = useState(false);

  const scrollIntoOffer = useCallback(() => {
    setScrollToOfferIntent(true);
    offerOffset && window.scrollTo({ behavior: "smooth", top: offerOffset });
    window.gtag && window.gtag("event", "scrolled_to_services");
    setTimeout(() => {
      setScrollToOfferIntent(false);
    }, 150);
  }, [offerOffset]);

  useEffect(() => {
    scrollToOfferIntent && scrollIntoOffer();
  }, [offerOffset, scrollIntoOffer, scrollToOfferIntent]);

  useEffect(() => {
    const checkShouldBeShown = () => {
      const currentOffset =
        document.documentElement.scrollTop || document.body.scrollTop;
      setCurrentOffset(currentOffset + 1);
    };
    checkShouldBeShown();

    ["scroll", "resize"].forEach((event) =>
      window.addEventListener(event, checkShouldBeShown)
    );

    return () => {
      ["scroll", "resize"].forEach((event) =>
        window.removeEventListener(event, checkShouldBeShown)
      );
    };
  }, [offerOffset]);

  useEffect(() => {
    if (offerOffset && currentOffset && offerOffset <= currentOffset + 1) {
      setShowAction(false);
    } else {
      setShowAction(true);
    }
  }, [currentOffset, offerOffset]);

  return (
    <>
      {showAction && (
        <div className="FixedAction">
          <p>
            <span className="secondary">
              Pšš.. Sháníte pomoc s&nbsp;růstem?
            </span>
          </p>
          <CTA onClick={scrollIntoOffer}>Sháním!</CTA>
        </div>
      )}
    </>
  );
};
