import React from "react";
import "./Projects.scss";
import { projectDefinitions } from "../../constants";


interface ProjectsProps {
  columnCount: number,
  projectDefinitions: typeof projectDefinitions
}

export const Projects: React.FC<ProjectsProps> = ({columnCount, projectDefinitions}) => {
  const projectCount = projectDefinitions.length;
  let columns: JSX.Element[][] = [];
  for (let i = 0; i < columnCount; i++) {
    columns[i] = [];
  }
  for (let i = 0; i < projectCount; i++) {
    const pd = projectDefinitions[i];
    columns[i % columnCount].push(
      <div className="Projects__project" key={pd.name}>
        <div className="Projects__project__icon">
          <img alt={`${pd.name} icon`} src={pd.icon}></img>
        </div>
        <p className="Projects__project__name">{pd.name}</p>
        <p className="Projects__project__for">{pd.for}</p>
        <p className="Projects__project__what">{pd.what}</p>
        <p className="Projects__project__description">{pd.description}</p>
      </div>
    );
  }
  return (
    <div className="Projects">
      {columns.map((column, idx) => {
        return (
          <div
            key={`projects-column-${idx}`}
            className="Projects__column"
            style={{ width: `${Math.floor(100 / columnCount)}%` }}
          >
            {column}
          </div>
        );
      })}
    </div>
  );
};

export default Projects;
