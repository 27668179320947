import projectIcon_adluxHolo from "./assets/projectIcon_adluxHolo.png";
import projectIcon_ByDevil from "./assets/projectIcon_ByDevil.png";
import projectIcon_codeCoach from "./assets/projectIcon_codeCoach.png";
import projectIcon_fantasymuzeum from "./assets/projectIcon_fantasymuzeum.png";
import projectIcon_grandeGlamping from "./assets/projectIcon_grandeGlamping.png";
import projectIcon_keramikaRosice from "./assets/projectIcon_keramikaRosice.png";
import projectIcon_mangado from "./assets/projectIcon_mangado.png";
import projectIcon_metoda from "./assets/projectIcon_metoda.png";
import projectIcon_nicshop from "./assets/projectIcon_nicshop.png";
import projectIcon_notillum from "./assets/projectIcon_notillum.png";
import projectIcon_psychosomatika from "./assets/projectIcon_psychosomatika.png";
import projectIcon_sevela from "./assets/projectIcon_sevela.png";
import projectIcon_starWalker from "./assets/projectIcon_starWalker.png";
import projectIcon_vzajemne_v_pohybu from "./assets/projectIcon_vzajemne_v_pohybu.png";

export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const projectDefinitions = [
    {
      name: "Notillum",
      for: "AmnioM Sideproject",
      what: "Startup support",
      description: (
        <i>
          O podklady AmnioMu se v dalším plánování opřel projekt Notillum.{" "}
          <span className="primary">
            Validoval se nápad, experimentálně zjistila cena za zájemce
          </span>{" "}
          o produkt a <span className="primary">analyzoval tržní potenciál.</span>
        </i>
      ),
      icon: projectIcon_notillum,
    },
    {
      name: "Vzájemně v pohybu",
      for: "MUDr. T. Horká",
      what: "Růstová stimulace",
      description: (
        <i>
          Mladí muži z AmnioMu jsou profesionálové každým coulem.{" "}
          <span className="primary">
            Flexibilita, operativnost, úžasná kreativita
          </span>{" "}
          je jejich vizitkou. Tito muži své práci rozumí. Přeji jim mnoho
          spokojených klientů.
        </i>
      ),
      icon: projectIcon_vzajemne_v_pohybu,
    },
    {
      name: "Reality Ševela",
      for: "F. Ševela",
      what: "Noví zákazníci",
      description: (
        <i>
          Jsem až příjemně zaskočen Vaší{" "}
          <span className="primary">kreativitou a rychlostí.</span> Ze spolupráce
          s Vámi <span className="primary">jsem nadšený !!!</span> :-)
        </i>
      ),
      icon: projectIcon_sevela,
    },
    {
      name: "NicShop",
      for: "AmnioM Sideproject",
      what: "Startup support",
      description: (
        <i>
          Jako AmnioM jsme jediná agentura, které se podařilo{" "}
          <span className="primary">prodat nic!</span> Na náš úspěch v projektu
          NicShop jsme hrdí a{" "}
          <span className="primary">přejeme spokojenost s koupeným Nic</span> všem
          našim zákazníkům.
        </i>
      ),
      icon: projectIcon_nicshop,
    },
    {
      name: "Fantasy Muzeum",
      for: "Manželé Krištofovi",
      what: "Noví zákazníci",
      description: (
        <i>
          Spolupráce s AmnioM týmem nás mile překvapila. Pánové jsou{" "}
          <span className="primary">
            plní moderních nápadů, komunikují rychle a vyřeší za klienta vše
            potřebné
          </span>
          . Tým budeme určitě doporučovat dál, a i my, v případě potřeby, se na
          AmnioM tým opět rádi obrátíme.
        </i>
      ),
      icon: projectIcon_fantasymuzeum,
    },
    {
      name: "Keramika Rosice",
      for: "Ing. V. Nepevná",
      what: "Noví zákazníci",
      description: (
        <i>
          Děkuji AmnioMu za <span className="primary">profesionální přístup</span>{" "}
          při přípravě a zpracování mých webových stránek. Veškeré moje požadavky
          a náměty byly rychle zpracovány k mé{" "}
          <span className="primary">naprosté spokojenosti</span>. Spolupráce s
          vaší firmou byla <span className="primary">vstřícná a operativní</span>.
        </i>
      ),
      icon: projectIcon_keramikaRosice,
    },
    {
      name: "AdLux Holo",
      for: "B. Greguš",
      what: "Noví zákazníci",
      description: (
        <i>
          Team AmnioM si zakládá na tom, aby pečlivě{" "}
          <span className="primary">poznali svého zákazníka</span>, analyzovali
          jeho potřeby a sestavili mu web přímo na míru tak, aby co{" "}
          <span className="primary">nejlépe působil na cílovou skupinu</span>.
          Poměr ceny, kterou platíte za dodanou kvalitu, je{" "}
          <span className="primary">velmi dobrý</span>.
        </i>
      ),
      icon: projectIcon_adluxHolo,
    },
    // {
    //     name: 'SvětChillu',
    //     for: 'V. Richter',
    //     what: 'Informační infuze',
    //     description: <>Doplnit, tohle doplnit, tohle doplnit, tohle doplnit, tohle doplnit, tohle doplnit, tohle doplnit, tohle doplnit, tohle doplnit, tohle doplnit, tohle</>,
    //     icon: projectIcon_svetChillu
    // },
    {
      name: "PM Psychosomatika",
      for: "Mgr. P. J. Macků, PhD.",
      what: "Růstová stimulace",
      description: (
        <i>
          <span className="primary">
            V krátké době jsem měl více klientů, než jsem si původně představoval.
          </span>{" "}
          Syn a pánové mi nejen pomohli v tom, co jsem chtěl, ale také mi{" "}
          <span className="primary">představili další možnosti</span> propagace, o
          kterých jsem ani nevěděl. Příště vím zcela jistě, že až budu potřebovat
          podobné služby,{" "}
          <span className="primary">znovu se na AmnioM obrátím.</span> Všem tuto
          firmu doporučuji.
        </i>
      ),
      icon: projectIcon_psychosomatika,
    },
    {
      name: "CodeCoach",
      for: "AmnioM Sideproject",
      what: "Startup support",
      description: (
        <i>
          AmnioM <span className="primary">profitabilně rozjel</span> koučování
          programování. Kampaně začaly mít{" "}
          <span className="primary">pozitivní a předvídatelnou návratnost</span>.
        </i>
      ),
      icon: projectIcon_codeCoach,
    },
    // {
    //     name: 'VoxTox',
    //     for: 'J. Hasoň',
    //     what: 'Růstová stimulace',
    //     description: <>Doplnit, tohle doplnit, tohle doplnit, tohle doplnit, tohle doplnit, tohle doplnit, tohle doplnit, tohle doplnit, tohle doplnit, tohle doplnit, tohle</>,
    //     icon: projectIcon_VoxTox
    // },
    {
      name: "ByDevil",
      for: "S. Kadlecová",
      what: "Startup support",
      description: (
        <i>
          AmnioM mi pomohl <span className="primary">rozjet prodej triček</span>.
          Domluvili jsme se, že za mě obstarají všechno kolem, a já tak budu moct
          dělat jen to, co mě baví, což je kreslení.
        </i>
      ),
      icon: projectIcon_ByDevil,
    },
    {
      name: "Grande Glamping",
      for: "K. Tesař",
      what: "Startup support",
      description: (
        <i>
          Všechno funguje, stránka se mi líbí, můžeme přijímat objednávky.{" "}
          <span className="primary">Good job borci!</span>
        </i>
      ),
      icon: projectIcon_grandeGlamping,
    },
    {
      name: "Starwalker",
      for: "J. Bednář",
      what: "Růstová stimulace",
      description: (
        <i>
          Optimalizace webu se dokončily{" "}
          <span className="primary">bez problémů a podle domluvy</span>. Těšíme se
          na další spolupráci!
        </i>
      ),
      icon: projectIcon_starWalker,
    },
    {
      name: "Mangado",
      for: "E. Dvořáčková",
      what: "Startup support",
      description: (
        <i>
          AmnioM <span className="primary">všem doporučujeme!</span> Udělali nám
          pěkný barevný e-shop a do začátku{" "}
          <span className="primary">pomohli i svými radami</span>. Děkujeme! :)
        </i>
      ),
      icon: projectIcon_mangado,
    },
    {
      name: "Zahraniční Metoda",
      for: "AmnioM Kampaň",
      what: "Noví zákazníci",
      description: (
        <i>
          Kampaň o metodě, která pomáhá byznysům vydělat, nám vynesla ROAS{" "}
          <span className="primary">návratnost 3055 %!</span>
        </i>
      ),
      icon: projectIcon_metoda,
    },
  ];