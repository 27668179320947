import React from "react";
import "./Rules.scss";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Paragraph } from "../../UI/Paragraph/Paragraph";
import social_media from "../../assets/social_media.png";

interface RulesProps {

}

export const Rules: React.FC<RulesProps> = () => {
    return <div className="Rules">
        <Heading2>1. Prodejce musí myslet na prospěch kupujícího</Heading2>
        <Paragraph><span className="secondary">Trh je protkaný firmami, pro které jste jen DALŠÍ KLIENT.</span> Nic víc.</Paragraph>
        <Paragraph>Mucha, ploštice, otravný brouk, co vám leze po klávesnici.</Paragraph>
        <Paragraph>Akorát, že jim platíte..</Paragraph>
        <Paragraph>Takže se pokouší alespoň <i>tvářit</i>, že jim na vás záleží.</Paragraph>
        <Paragraph>Bylo to v byznysu vždycky, ale <span className="underline">nechci hrát podle těchto pravidel.</span></Paragraph>
        <Paragraph>Když už mám <span className="secondary">klienta, tak mi jde o to, aby rostl!</span></Paragraph>
        <Paragraph>Velká část trhu je <i>čistě</i> egoistická.</Paragraph>
        <Paragraph>Nechci, aby to vyznělo tak, že jsou takoví všichni. To určitě ne. Jsou tu firmy upřímné a se zájmem o klienta.</Paragraph>
        <Paragraph><i>Jen je někdy těžké na takovou narazit.</i></Paragraph>
        <Heading2>2. Kupující může vidět hodnotu jinde</Heading2>
        <Paragraph>Firmy se někdy neumí soustředit na hodnotu, kterou vnímá jejich klient. Místo toho jsou zaslepeny <i>svou</i> vizí hodnoty.</Paragraph>
        <Paragraph>Pak třeba říkají: <i>“No, podívejte, my potřebujeme lidem vysvětlit, k čemu naše aplikace je, a přinutit je ji využívat...”</i></Paragraph>
        <Paragraph><span className="secondary">Máme pocit, že když někdo nechce náš produkt, tak ho jen nechápe...</span></Paragraph>
        <Paragraph>Často je to obráceně. Třeba lidi chápou, k čemu náš produkt/služba je, ale prostě v tom nevidí hodnotu.</Paragraph>
        <Heading2><span className="secondary" style={{fontWeight: 900}}>Firmy musí sledovat, co chce trh.</span> Ne jen to, co chtějí ony samy.</Heading2>
        <Paragraph className="center larger">
            A tak, když budete dělat marketing a rozrůstat firmu, <span className="primary">odpoutejte se od VAŠICH představ o vašem zákazníkovi.</span>
        </Paragraph>
        <Paragraph className="center larger">
        <span className="primary">Soustřeďte se na JEHO představy o sobě samém.</span> Jeho potřeby. Udělejte si průzkum. Zavolejte spokojeným zákazníkům. Zjistěte, co opravdu chtějí...
        </Paragraph>
        <Paragraph className="margin">I my v tom dělali chybu!</Paragraph>
        <Paragraph>A klidně překopejte celý byznys kvůli nim. <span className="secondary">Však ty nejúspěšnější firmy udělaly přesně toto!</span></Paragraph>
        <img alt="Social Media Ilustration" src={social_media}></img>
        <Paragraph>Instagram, dřív Burbn, byl dělaný pro lokální komunity, aby se mohly domluvit na setkání, a jako sekundární feature bylo postování fotek.</Paragraph>
        <Paragraph>No, přišlo se na to, že uživatelům jde těměř <i><span className="underline">výhradně</span></i> o postování fotek. A tak má Instagram úspěch, protože šel tam, kam trh chtěl, aby šel.</Paragraph>
        <Paragraph><span className="secondary">Instagram OPUSTIL svou původní myšlenku</span></Paragraph>
        <Heading2>Je to až děsivý se zaobírat myšlenkou, že náš nápad, předmět podnikání, může být radikálně změněn.</Heading2>
        <Heading2>Přesto je to <span className="underline"><i>kritický krok</i></span> k rychlému růstu - <span className="secondary">neustále se soustředit na uživatele/klienta/zákazníka.</span></Heading2>
        <Paragraph>I naše firma má tuhle historii - dřív jsme dělali <i>“webovky”</i>.</Paragraph>
        <Paragraph>Pěkné webovky, webovky s animacemi, krásný design...</Paragraph>
        <Paragraph>Samozřejmě jsme měli problém se zakázkami, protože animace a design nikdo nechtěl. <span className="secondary">Lidi chtěli vydělávat!</span></Paragraph>
        <Paragraph>Takže tu máme AmnioM Growth, který se soustředí na všestranné a efektivní rozrůstání firem.</Paragraph>
        <Heading2>3. Tvořme z hypotéz teorie</Heading2>
        <Paragraph>Jako podnikatelé máme hypotézy, co naše cílovka chce.</Paragraph>
        <Paragraph>Hypotéza je potřeba, ale musíme ji přetavit na <i>teorii.</i></Paragraph>
        <Heading2>To znamená, že musíme věci <span className="secondary">podkládat daty.</span></Heading2>
        <Paragraph>Nesmíme si myslet, že věci chápeme. Musíme mít pokoru a být schopní uznat, že se můžeme mýlit.</Paragraph>
        <Heading2>Musíme mít <span className="secondary">touhu zjistit, že jsme se mýlili!</span></Heading2>
        <Paragraph>Protože jen tak budeme schopní opravdu růst. Jen tak budeme schopní <span className="underline">vycházet z <i>dat</i> a ne z <i>domněnek</i></span>.</Paragraph>
        <Paragraph><span className="secondary">Nezůstávejme u hypotéz. Tvořme podložené <span className="underline"><i>teorie</i></span>.</span></Paragraph>
        <Paragraph>A toto se neaplikuje jen na chápání cílovky.</Paragraph>
        <Heading2>Tohle je <span className="secondary">kritické ve VŠECH oblastech růstu</span> firmy.</Heading2>
        <Paragraph>Od nacenění služeb, přes aktivitu uživatelů, až po spokojenost zaměstnanců...</Paragraph>
        <Paragraph>Ke všemu chceme mít data. Proč?</Paragraph>
        <Paragraph>Protože jakmile nám něco důležitého unikne, může to rozvrátit celé růstové snažení.</Paragraph>
        <Paragraph>Neříkám, že byste měli mít tabulku v excelu, kolikrát si každý váš zaměstnanec udělal kávu za poslední kvartál...</Paragraph>
        <Paragraph><span className="secondary">Je potřeba měřit to, co se zdá být relevantní, a to, co jsme ověřili, že relevantní je.</span></Paragraph>
        <Paragraph>Naštěstí s tímhle byste se neměli muset štvát sami. <span className="primary">Na to jsou tu právě růstové agentury</span> typu AmnioM Growth, které by vám s tímhle měly pomáhat.</Paragraph>
    </div>
};

export default Rules;