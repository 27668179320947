import React from "react";

import "./Author.scss";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Paragraph } from "../../UI/Paragraph/Paragraph";
import author from '../../assets/author.png';

interface AuthorProps {

}

export const Author: React.FC<AuthorProps> = () => {
    return <div className="Author">
        <Heading2>O Autorovi</Heading2>
        <div className="Author__card">
            <div className="Author__card__left">
                <img alt="Josef Macků" src={author}></img>
            </div>
            <div className="Author__card__right">
                <div className="Author__card__paragraphs">
                    <Paragraph>Josef Macků se o podnikání zajímal odjakživa. Spíše než vlastní výdělek ho vždy lákaly vize a možnosti.</Paragraph>
                    <Paragraph>Je spoluzakladatelem růstové agentury AmnioM Growth. Mezi jeho zájmy patří psychologie, data a osobní rozvoj.</Paragraph>
                </div>
            </div>
        </div>
        <Paragraph><b>Pozn. autora:</b> Čím dřív se začne, tím dál se dostane.</Paragraph>
        <Paragraph>Kdykoli jsem se něco učil <i>(třeba programovat, psát texty)</i>,</Paragraph>
        <Paragraph>čím dřív jsem to uměl, tím rychleji jsem mohl jít dál.</Paragraph>
        <Paragraph><i>Efekt sněhové koule.</i></Paragraph>
        <Paragraph>Např. psaní všema 10! Skvělý příklad.</Paragraph>
        <Paragraph>Cokoli dalšího pak děláte má mnohem větší <i>efektivitu.</i></Paragraph>
        <Paragraph>A s rozplánovaným růstem to je stejně.</Paragraph>
        <Paragraph>Řekněte si o strategii teď.</Paragraph>
        <Paragraph><span className="secondary">Můžete si to nechat projít hlavou, ale <i>přijdete</i> o čas.</span></Paragraph>
        <Paragraph><span className="primary">Ať se vám a vašemu podnikání daří, hodně kvalitních byznys partnerů a ať vám vaše práce dává smysl.</span></Paragraph>
        <Paragraph><span className="secondary">Hezký den!</span><br></br>
            Josef Macků<br></br>
            za AmnioM Growth</Paragraph>
    </div>
};

export default Author;