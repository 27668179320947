import { useState, useCallback, useEffect, useRef } from "react";

import Popup from "./UI/Popup/Popup";
import { Header } from "./sections/Header/Header";
import Introduction from "./sections/Introduction/Introduction";
import { Why } from "./sections/Why/Why";
import Rules from "./sections/Rules/Rules";
import Thing from "./sections/Thing/Thing";
import Growth from "./sections/Growth/Growth";
import Task from "./sections/Task/Task";
import Sometimes from "./sections/Sometimes/Sometimes";
import ForYou from "./sections/ForYou/ForYou";
import MainProjects from "./sections/MainProjects/MainProjects";
import Author from "./sections/Author/Author";
import Order from "./sections/Order/Order";
import { FixedAction } from "./UI/FixedAction/FixedAction";

export const App = () => {
  const [popupText, setPopupText] = useState<string | null>(null);
  const [popupError, setPopupError] = useState<boolean>(false);
  const [popupSuccess, setPopupSuccess] = useState<boolean>(false);

  const offerElement = useRef<HTMLDivElement>(null);
  const [offerOffset, setOfferOffset] = useState<number | null>(null);

  useEffect(() => {
    const setOfferElementOffset = () => {
      setOfferOffset(offerElement.current?.offsetTop || null);
    };
    setOfferElementOffset();

    ["scroll", "resize"].forEach((event) =>
      window.addEventListener(event, setOfferElementOffset)
    );

    return () => {
      ["scroll", "resize"].forEach((event) =>
        window.removeEventListener(event, setOfferElementOffset)
      );
    };
  }, [offerElement]);

  const popup = useCallback(
    (text: string, isError: boolean = false, isSuccess?: boolean) => {
      setPopupError(isError);
      setPopupSuccess(!!isSuccess);
      setPopupText(text);
    },
    []
  );

  const [orderActive, _setIsOrderActive] = useState(false);

  const currentYear = new Date().getFullYear();

  const setIsOrderActive = useCallback((v: boolean) => {
    if (v) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
    _setIsOrderActive(v);
  }, []);

  return (
    <div className="App" style={{ overflow: "hidden" }}>
      {orderActive && (
        <Order setIsOrderActive={setIsOrderActive} popup={popup}></Order>
      )}
      <Header></Header>
      <FixedAction offerOffset={offerOffset} />
      <Introduction></Introduction>
      <Why></Why>
      <Rules></Rules>
      <Thing></Thing>
      <Growth></Growth>
      <Task></Task>
      <Sometimes></Sometimes>
      <ForYou ref={offerElement} setIsOrderActive={setIsOrderActive}></ForYou>
      <MainProjects></MainProjects>
      <Author></Author>
      <div className="Footnote">
        <a
          target="_blank"
          rel="noreferrer"
          href="GDPR.pdf"
          className="Footnote__a"
        >
          Ochrana osobních údajů
        </a>{" "}
        |{" "}
        <a
          target="_blank"
          rel="noreferrer"
          className="Footnote__a"
          href="https://www.amniomgrowth.com"
        >
          © {currentYear} AmnioM Growth (IČO: 11667052)
        </a>
      </div>
      {popupText && (
        <Popup
          isSuccess={popupSuccess}
          isError={popupError}
          onDismiss={() => {
            setPopupText(null);
            setPopupError(false);
          }}
          text={popupText}
        ></Popup>
      )}
    </div>
  );
};
