import { forwardRef } from "react";
import "./ForYou.scss";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Paragraph } from "../../UI/Paragraph/Paragraph";
import { Heading1 } from "../../UI/Heading1/Heading1";
import AmnLogo from "../../assets/amn_logo.png";
import ProjectGroup from "../../components/ProjectGroup/ProjectGroup";
import { projectDefinitions } from "../../constants";

import { trackingFunction } from '../../utils/trackingFunction';

interface ForYouProps {
  setIsOrderActive: (v: boolean) => void;
}

export const ForYou = forwardRef<HTMLDivElement, ForYouProps>(
  ({ setIsOrderActive }, ref) => {
    // TODO: Enable tracking
    // const trackContactIntent = (cta_id: number, cta_text: string) => {
    //   window.gtag &&
    //     window.gtag("event", "contact_intent", { cta_id, cta_text });
    // };

    return (
      <div ref={ref} className="ForYou">
        <Heading1>
          Pro ty z vás, které{" "}
          <span className="primary">zaujala naše práce,</span> něco málo o nás:
        </Heading1>
        <Paragraph>
          Naše služby jsme přeorientovávali podle potřeb klientů, až jsme se
          dostali k{" "}
          <span className="secondary">
            výkonnostnímu a růstovému marketingu
          </span>{" "}
          a tzv.{" "}
          <span className="secondary">
            <i>growth hackingu</i>
          </span>
          .
        </Paragraph>
        <Paragraph>
          <span className="primary">
            Growth hacking = techniky a přístupy, které využívají ty
            nejúspěšnější a nejrychleji rostoucí firmy světa.
          </span>
        </Paragraph>
        <Paragraph>
          V této době dáváme MAXIMUM do toho, abychom klientům pomohli{" "}
          <i>
            <span className="underline">všestranně.</span>
          </i>
        </Paragraph>
        <Paragraph>
          <i>
            • Strategie,<br></br>• průzkumy,<br></br>• experimenty,<br></br>•
            kampaně,<br></br>• optimalizace<br></br>• ...
          </i>
        </Paragraph>
        <Paragraph>
          Naší misí je <span className="primary">pomoct byznysům</span>{" "}
          dosáhnout větších{" "}
          <span className="primary">úspěchů, efektivity i autenticity.</span>
        </Paragraph>
        <Heading2 className="bigMargin">
          Ať už jste jakýkoli byznys, budeme vám schopní{" "}
          <span className="secondary">
            poradit (zdarma) a dlouhodobě pomoct s růstem.
          </span>
        </Heading2>
        <div className="ForYou__Card">
          <div className="ForYou__Card__left">
            <img alt="AmnioM Growth Logo" src={AmnLogo}></img>
          </div>
          <div className="ForYou__Card__right">
            <p>
              <b>AmnioM Growth</b>
            </p>
            <p>amniomgrowth.com</p>
            <p>
              <i>(+420) 735 044 114</i>
            </p>
            <p>
              <i>team@amniomgrowth.com</i>
            </p>
          </div>
        </div>
        <button
          id="markgrow_0"
          onClick={() => {
            trackingFunction("markgrow_0")
            setIsOrderActive(true);
          }}
          className="ForYou__button"
        >
          Chci se spojit
        </button>
        <Paragraph align="center">Růstovou strategii vám zpracujeme zdarma.</Paragraph>
        <Heading2 fontWeight="500" className="bigMargin"><span className="primary">Utneme STAGNACI vašeho podnikání</span></Heading2>
        <Paragraph>Zkusili jste toho už <i>hodně</i>.</Paragraph>
        <Paragraph>A nic <span className="secondary">nefungovalo tak, jak byste si představovali.</span></Paragraph>
        <Paragraph>Nikdy si nejste jistí, jestli ten další krok bude ten správný.</Paragraph>
        <Paragraph>V tuhle chvíli můžete udělat možná <span className="underline"><i>nejlepší</i> rozhodnutí</span> tohoto roku...</Paragraph>
        <Paragraph><span className="primary">A to je navrhnout a realizovat svou růstovou strategii.</span></Paragraph>
        <Paragraph>Krok za krokem, vědecky a systematicky.</Paragraph>
        <Heading2 fontWeight="500">Potřebujete <span className="secondary">předvídatelný systém</span> na generování poptávek.</Heading2>
        <Paragraph>Potřebujete <span className="secondary">víc času</span> na řešení důležitých věcí.</Paragraph>
        <Paragraph>Potřebujete vidět, že <span className="secondary">vaše práce má výsledky</span>, které si zaslouží.</Paragraph>
        <Paragraph>Kdykoli jsem měl v životě velký cíl, <span className="secondary">NEpřivedla mě k němu jen práce a vytrvalost...</span></Paragraph>
        <Heading2><span className="primary">ZPRAVIDLA jsem viděl výsledky AŽ když jsem zavedl systém.</span></Heading2>
        <Paragraph>Bez systému a strategie se <i>motáme pořád dokola.</i></Paragraph>
        <Paragraph>Stejné problémy.</Paragraph>
        <Paragraph><span className="secondary">Frustrující <i>začarovaný</i> kruh.</span></Paragraph>
        <Paragraph>Jako bychom si dali až moc velké cíle...</Paragraph>
        <Paragraph>Ale <span className="secondary">tohle není o velikosti cílů.</span></Paragraph>
        <Paragraph><span className="underline"><i>Tohle je o tom, jestli máme z toho kruhu mapu ven.</i></span></Paragraph>
        <Heading2><span className="primary"><i>Tohle je o tom, jestli máme z toho kruhu mapu ven.</i></span></Heading2>
        <Paragraph>A touhle mapou je růstová strategie.</Paragraph>
        <Paragraph>Bez růstové strategie se byznysy akorát brodí bahnem.</Paragraph>
        <Paragraph>Podnikatelé pracují celý den na těch stejných, nezajímavých činnostech, které je ani nikam neposunou.</Paragraph>
        <Paragraph>Dnes je na trhu <span className="secondary">neférová výhoda téměř nutnost.</span></Paragraph>
        <Paragraph>Podniky se oddávají neúprosné rivalitě, <span className="underline">jako kdyby hrály <i>The Hunger Games.</i></span></Paragraph>
        <Paragraph><i>“Leader odvětví padnul, ať žije leader odvětví!”</i></Paragraph>
        <Paragraph><span className="secondary">Je to jako hledat poklad.</span></Paragraph>
        <Paragraph>Můžete mít ty nejlepší bagry a nejlepší hledače kovů, ...</Paragraph>
        <Heading2 fontWeight="500">Ale pak přijde někdo s dřevenou lopatou, kdo má k pokladu mapu, <span className="secondary">a získá všechno.</span></Heading2>
        <Paragraph>Mapa k pokladu je jeho neférová výhoda.</Paragraph>
        <Paragraph>Pro vás je touhle mapou růstová strategie.</Paragraph>
        <Paragraph><span className="secondary">Protože se strategií zvládnete víc, než kdokoli bez ní.</span></Paragraph>
        <Heading2 className="bigMargin"><span className="secondary">My vám růstovou strategii zpracujeme zdarma.</span></Heading2>
        <Heading2 fontWeight="500"><span className="primary">Teď je čas si o ni říct.</span></Heading2>
        <Heading2 fontWeight="500">Nejhorší scénář? Strategie se vám nebude líbit.</Heading2>
        <Heading2 fontWeight="500">V tuhle chvíli neriskujete, jen získáváte.</Heading2>
        <button
          id="markgrow_1"
          onClick={() => {
            trackingFunction("markgrow_1");
            setIsOrderActive(true);
          }}
          className="ForYou__button"
        >
          Asi Potřebuju Růstovou Strategii
        </button>
        <Paragraph color="#56B62E" align="center">Zaplňuje se nám kapacita na dva týdny dopředu, dejte o sobě vědět co nejdřív!</Paragraph>
        <ProjectGroup projectDefinitions={[projectDefinitions[1], projectDefinitions[6]]}></ProjectGroup>
        <Heading2 fontWeight="500" fontFamily="Itim, sans-serif"><span className="secondary">“Je to pro mě?”</span></Heading2>
        <Paragraph>Jestli chcete s podnikáním uspět, víc růst...</Paragraph>
        <Paragraph>pak je růstová strategie a její realizace <span className="secondary"><i>právě to, co potřebujete.</i></span></Paragraph>
        <Paragraph>NENÍ to pro vás, jestli<br></br>
          • chcete držet svůj profit na aktuálních hodnotách,<br></br>
          • nechcete svůj byznys dál rozvíjet,<br></br>
          • vám stačí, kde jste teď.</Paragraph>
        <Paragraph>Dočetli jste až sem...</Paragraph>
        <Paragraph>takže o růstovou strategii zájem dost možná máte.</Paragraph>
        <Paragraph><span className="secondary">Nejraději tvoříme strategie a pracujeme pro</span><br></br>
          • <i>SaaS a aplikace,</i><br></br>
          • <i>služby,<br></br>
          • fyzické produkty,<br></br>
          • informační produkty a<br></br>
          • <span className="secondary">kohokoli, kdo je do svého podnikání nadšený!</span></i></Paragraph>
        <Heading2 className="bigMargin" fontWeight="500">Uděláme vám růstovou strategii bezplatně.</Heading2>
        <button
          id="markgrow_2"
          onClick={() => {
            trackingFunction("markgrow_2");
            setIsOrderActive(true);
          }}
          className="ForYou__button"
        >
          Dobře, udělejte mi Růstovou Strategii!
        </button>
        <Paragraph color="#56B62E" align="center">Zaplňuje se nám kapacita na dva týdny dopředu, dejte o sobě vědět co nejdřív!</Paragraph>
        <ProjectGroup projectDefinitions={[projectDefinitions[5], projectDefinitions[2]]}></ProjectGroup>
        <Heading2 fontFamily="Itim, sans-serif" fontWeight="500"><span className="secondary">“Proč dáváte celou strategii bezplatně, když je tak cenná?”</span></Heading2>
        <Paragraph>Máte pravdu, růstová strategie <i>je</i> cenná.</Paragraph>
        <Paragraph>Uplatní se na ni <span className="secondary">všechno, co víme, a všechny naše zkušenosti.</span></Paragraph>
        <Paragraph>Přesto ji dáváme zdarma...</Paragraph>
        <Paragraph>Zaprvé <i>chceme vidět, že víc byznysů využivá svůj plný potenciál.</i></Paragraph>
        <Paragraph>Máme dobrý pocit, když se nám povede posunout byznys nebo celé odvětví k lepšímu.</Paragraph>
        <Paragraph>Druhý důvod je, že se nám to, <i>i tak,</i> vyplatí.</Paragraph>
        <Paragraph><span className="secondary">Když podnikatelé vidí strategii a její potenciál, chtějí ji co nejdřív realizovat.</span></Paragraph>
        <Paragraph>A i samotná realizace je naše práce.</Paragraph>
        <Paragraph><span className="primary">Na to je ale brzo</span>, teď si jen řekněte o svou strategii.</Paragraph>
        <Paragraph><span className="secondary">A řekněte si hned! Sami víte, že když se na to vykašlete teď, už se k tomu nevrátíte!</span> 👀</Paragraph>
        <Heading2 fontWeight="500" className="bigMargin">Další tlačítko tu už nebude.</Heading2>
        <button
          id="markgrow_3"
          onClick={() => {
            trackingFunction("markgrow_3");
            setIsOrderActive(true);
          }}
          className="ForYou__button"
        >
          Chci Růstovou Strategii
        </button>
        <Paragraph align="center" color="#56B62E">Zaplňuje se nám kapacita na dva týdny dopředu, dejte o sobě vědět co nejdřív!</Paragraph>
        <Heading2 fontWeight="500" className="bigMargin">V tuhle chvíli <span className="underline">neriskujete</span>. <span className="secondary">Dostáváte podklady pro váš další růst.</span> To nejhorší, co se může stát, je, že se vám nebudou líbit.</Heading2>
        <Heading2 fontWeight="500">V žádném případě po vás <span className="secondary"><i>nebudeme chtít peníze.</i></span></Heading2>
        <Paragraph className="bigMargin">V Česku jsou růstové strategie <i>zatím</i> poměrně vzácná věc.</Paragraph>
        <Paragraph>To znamená jen jedno...</Paragraph>
        <Paragraph><span className="primary">Ti, kteří se ke strategiím dostanou jako první, ...</span></Paragraph>
        <Paragraph><span className="secondary">... asi si domyslíte.</span></Paragraph>
        <Paragraph>Konkurence nespí.</Paragraph>
        <Paragraph>Nejhorší situace by pro vás byla, kdyby s růstovou strategií <span className="secondary">začala konkurence dřív.</span></Paragraph>
      </div>
    );
  }
);

export default ForYou;
