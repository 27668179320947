import "./Header.scss";

import brainstorm from "../../assets/brainstorm.png";

import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  return (
    <div className="Header">
      <div className="BottomMarker left"></div>
      <div className="BottomMarker right"></div>
      <div className="Header__background">
        <img
          src={brainstorm}
          alt="Drawing on a table"
          className="Header__img"
        ></img>
      </div>
      <Heading1 className="Header__heading1">
        Jak je to doopravdy s&nbsp;<span>marketingem a rozrůstáním firmy?</span>
      </Heading1>
      <Heading2 className="Header__heading2">
        Kvalitní <span>základ pro růst a perspektiva</span>, s jakou k růstu a
        marketingu přistupovat.
      </Heading2>
    </div>
  );
};

export default Header;
