import "./Order.scss";
import { useState, useCallback } from "react";
import axios from "axios";

import { Heading3 } from "../../UI/Heading3/Heading3";
import { CTA } from "../../UI/CTA/CTA";

interface OrderProps {
  popup: (text: string, isError: boolean, isSuccess?: boolean) => void;
  setIsOrderActive: (v: boolean) => void;
}

export const validateEmail: (v: string) => boolean = (email: string) => {
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateFirstName: (v: string) => boolean = (name: string) => {
  if (!(name.trim().length >= 2)) {
    return false;
  }
  return true;
};

export const Order: React.FC<OrderProps> = ({ popup, setIsOrderActive }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [checked, setChecked] = useState(false);
  const [CTAWasClicked, setCTAWasClicked] = useState(false);

  const onCTAClicked = useCallback(() => {
    if (CTAWasClicked) {
      return;
    }
    if (!checked) {
      popup("Prosím, odsouhlaste zpracování osobních údajů", true);
      return;
    }
    if (!validateEmail(email)) {
      popup("Zkontrolujte prosím, zda jste zadali mail správně", true);
      return;
    }
    if (!validateFirstName(name)) {
      popup("Jméno musí mít minimálně dvě písmena", true);
      return;
    }
    window.gtag && window.gtag("event", "generate_lead");
    window.fbq && window.fbq("track", "Contact");
    //@ts-ignore-next-line
    window.ttq && window.ttq.track("Contact");
    setCTAWasClicked(true);

    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/meeting", {
        name,
        email,
        message,
      })
      .then(() => {
        setCTAWasClicked(false);
        popup("Hotovo, ozveme se!", false, true);
        setEmail("");
        setName("");
        setMessage("");
        setIsOrderActive(false);
      })
      .catch((e) => {
        setCTAWasClicked(false);
        const err = e.response.data.err as string;
        if (err) {
          if (err === "mail-err") {
            popup("Zkontrolujte prosím, zda jste zadali mail správně", true);
            return;
          }
          if (err === "name-err") {
            popup("Jméno musí mít minimálně dvě písmena", true);
            return;
          }
        }
        popup(
          "Došlo k chybě, prosím zkuste to znovu později. Nebo nám napište na team@amniomgrowth.com.",
          true
        );
      });
  }, [
    name,
    setName,
    email,
    setEmail,
    message,
    setMessage,
    checked,
    CTAWasClicked,
    setCTAWasClicked,
  ]);

  return (
    <div className="Order">
      <div className="Order__content">
        <div
          onClick={() => {
            setIsOrderActive(false);
          }}
          className="Order__cross"
        ></div>
        <label htmlFor="name">Vaše křestní jméno</label>
        <input
          className="Order__textInput"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          type="text"
          name=""
          id="name"
        />
        <label htmlFor="email">Váš e-mail</label>
        <input
          className="Order__textInput"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          name=""
          id="email"
        />
        <label htmlFor="message">Zpráva (nepovinné)</label>
        <textarea
          id="message"
          className="Order__textArea"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <div className="Order__checkboxInput">
          <label
            className={[
              "Order__checkboxInput__label",
              checked ? "Order__checkboxInput__label--checked" : "",
            ].join(" ")}
          >
            <input
              type="checkbox"
              onChange={(e) => {
                setChecked((v) => !v);
              }}
              className="Order__checkboxInput__input"
            ></input>
          </label>
          <p className="Order__checkboxInput__text">
            Souhlasím se{" "}
            <a target="_blank" href="GDPR.pdf">
              zpracováním osobních údajů
            </a>
          </p>
        </div>
        <CTA className={CTAWasClicked ? "loading" : ""} onClick={onCTAClicked}>
          {CTAWasClicked ? "Počkejte chvilku, prosím" : "Spojit se"}
        </CTA>
        <Heading3>
          Poradíme a vypracujeme vám zdarma i celou růstovou strategii. Pojďme
          začít!
        </Heading3>
      </div>
    </div>
  );
};

export default Order;
