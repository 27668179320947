import React from 'react';
import './ProjectGroup.scss';
import { useMediaQuery } from 'react-responsive';

import Projects from '../Projects/Projects';
import { projectDefinitions } from '../../constants';

interface ProjectGroupProps {
    projectDefinitions: typeof projectDefinitions;
}

export const ProjectGroup: React.FC<ProjectGroupProps> = ({ projectDefinitions }) => {
    let columnCount = 2;
    const isUnder900 = useMediaQuery({maxWidth: 900});
    if (isUnder900) {
        columnCount = 1;
    }
    return <div className='ProjectGroup'>
        <Projects projectDefinitions={projectDefinitions} columnCount={columnCount}></Projects>
    </div>
};

export default ProjectGroup;