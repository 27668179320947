import React from "react";
import "./Growth.scss";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Paragraph } from "../../UI/Paragraph/Paragraph";
import { Heading1 } from "../../UI/Heading1/Heading1";
import growth from '../../assets/growth.png';
import map from '../../assets/map.png';

interface GrowthProps {

}

export const Growth: React.FC<GrowthProps> = () => {
    return <div className="Growth">
        <Heading2>Firmy, které to dodržují, NEmůžou NEvydělávat.</Heading2>
        <Paragraph>Když dělají správně i ostatní věci... Je v tom samozřejmě víc.</Paragraph>
        <Paragraph><span className="secondary">Přišli jste sem kvůli tomu, abyste věděli, jak je to s růstem a marketingem.</span></Paragraph>
        <Heading2>Takže, jak si spojit všechny ty kousky skládačky, o kterých jsem mluvil, do vykonatelné <span className="secondary">růstové strategie?</span></Heading2>
        <Paragraph>Na začátek řeknu, že pokud se chcete o růst vaší firmy starat sami, <i><span className="underline">bez pomoci</span></i> externí agentury, zbytek tohoto článku ať vám slouží jako <span className="secondary">úvod do studia.</span></Paragraph>
        <Paragraph>Pro ty, kteří možnost využití růstové agentury zvažujete, to může být poměrně <span className="secondary">kompletní materiál.</span></Paragraph>
        <img alt="Growth" src={growth}></img>
        <Heading1><span className="primary">RŮSTOVÁ STRATEGIE</span> - Jak začít</Heading1>
        <Paragraph>Co vlastně růstová strategie <i>je?</i></Paragraph>
        <Paragraph><span className="secondary">Chytře vymyšlený postup, který bude vaše firma následovat.</span></Paragraph>
        <Paragraph>Když ji tvoříme, vyhodnocujeme, v jakém stavu firma aktuálně je, jaké jsou její možnosti <i>(ať už z pohledu financí, konexí, týmu nebo čehokoli jiného)</i> a do jakého stavu chceme firmu dostat.</Paragraph>
        <Paragraph>Mluvil jsem o <i>hypotézách</i> (nepodložené daty) a <i>teoriích</i> (podložené daty).</Paragraph>
        <Paragraph><span className="secondary">Když tvoříme růstovou strategii, může se stát, že <span className="underline  secondary">nemáme dost dat pro její vytvoření.</span></span></Paragraph>
        <Paragraph>Např. nemáme dostatek dat o klientele nebo o využívání našeho produktu.</Paragraph>
        <Heading2>Pak je prvním krokem DATA ZÍSKAT.</Heading2>
        <Paragraph>Způsobů, jak data získat, je hodně:<br></br>
        • dotazníky<br></br>
        • rozhovory<br></br>
        • focus groups<br></br>
        • data z internetu (např. fóra, sociální sítě, statistické reporty...)<br></br>
        • webová data z např. Google Analytics, z produktové analytiky (např. Mixpanel)....<br></br>
        </Paragraph>
        <Paragraph>Samozřejmě se soustředíme hlavně na ta data, která věříme, že <span className="secondary">pomůžou s vytvořením růstové strategie!</span></Paragraph>
        <Heading2>Když vybraná data máme, můžeme začít TVOŘIT RŮSTOVOU STRATEGII.</Heading2>
        <Paragraph>A teď... <span className="secondary">Co vlastně taková růstová strategie v sobě zahrnuje?</span></Paragraph>
        <Paragraph>Odpověď je těžká, <span className="secondary">může to být cokoli</span> 💁‍♂️</Paragraph>
        <Heading2>Schopnost tvořit růstové strategie vyžaduje <span className="secondary">vysokou flexibilitu, kreativitu, praktické i teoretické znalosti.</span></Heading2>
        <Paragraph>Často je strategie postavena na <span className="primary">růstovém marketingu.</span></Paragraph>
        <Paragraph>Růstový marketing je marketing, který se soustředí na to, aby rozrostl firmu.</Paragraph>
        <Paragraph>To znamená - nadefinujeme si, jaký je cíl (např. větší útrata nakupujících) a pomocí marketingu ho dosahujeme.</Paragraph>
        <Heading2>Rozdíl od tradičního marketingu je ten, že <span className="secondary">růstový marketing je neúspěšný, pokud nezlepšil metriky.</span></Heading2>
        <Paragraph>Tradiční marketing neúspěšný být nemůže, protože <span className="secondary">když už se rozhodnete vyhazovat peníze z okna</span> <i>(což tradiční marketing pro menší firmy esenciálně je),</i> <span className="secondary">je těžké to udělat neúspěšně :)</span></Paragraph>
        <Paragraph>
            Do prvků růstového marketingu patří např. toto:<br></br>
            • reklamy<br></br>
            • optimalizace konverzních poměrů<br></br>
            • promyšlený copywriting<br></br>
            • prodejní trychýře<br></br>
            • remarketing<br></br>
            • SEO<br></br>
            • e-mailový marketing...</Paragraph>
        <Paragraph>Je toho hodně - z toho důvodu je <span className="primary">občas lepší to přenechat agentuře,</span> které důvěřujete.</Paragraph>
        <Heading2><span className="secondary">Růstový marketing není jediná věc, se kterou růstové strategie počítají.</span> Díváme se na byznys KOMPLEXNĚ.</Heading2>
        <Paragraph><b>Z praxe:</b></Paragraph>
        <Paragraph>- Je aplikace a uživatel ji průměrně používá jen 5 dní a pak si ji odinstaluje? Použijeme <span className="secondary">techniky zvýšení retence.</span></Paragraph>
        <Paragraph>- Chceme zvýšit počet nákupů v aplikaci? Vyzkoušíme techniky aktivizace uživatelů, <span className="secondary">např. kupony, slevy, zjednodušení objednávky...</span></Paragraph>
        <Paragraph>Nedávno měl náš klient problém s velkým objemem komunikace s jeho vlastními klienty. Vymysleli jsme, jak spoustu z té komunikace automatizovat <span className="secondary">zavedením B2B e-shopu,</span> a ušetřili mu tak zdroje a výdaje.</Paragraph>
        <Paragraph><span className="underline">Jak říkám, růstová strategie je komplexní věc.</span></Paragraph>
        <Paragraph>Může zahrnovat např. i věrnostní plány, affiliate programy, optimalizace nacenění... <b><span className="secondary">esenciálně cokoli.</span></b></Paragraph>
        <Heading2>VYTVÁŘENÍ růstových plánů:</Heading2>
        <Paragraph className="center">
            1. Indetifikujte, <span className="secondary">kde jste</span><br></br>
            2. Identifikujte, <span className="secondary">kam jdete</span><br></br>
            3. Identifikujte <span className="secondary">překážky</span><br></br>
            4. Vytvořte <span className="primary">řešení</span><br></br>
        </Paragraph>
        <img alt="Map With Treasure" src={map}></img>
        <Paragraph>Principiálně jednoduché, ale vyžaduje to všestranné schopnosti.</Paragraph>
        <Paragraph>Růstové agentury by všechny potřebné schopnosti mít měly a agentury ještě mají jednu výhodu...</Paragraph>
        <Paragraph><span className="secondary">Nemusíte formovat růstový tým ve své vlastní společnosti a <span className="underline secondary">zavazovat se k tomu, že budete jeho členy živit.</span></span></Paragraph>
        <Paragraph>Pro menší a střední firmy je většinou vlastní růstový tým finančně <i><span className="underline">příliš náročný a příliš zavazující.</span></i></Paragraph>
        <Heading2>RŮSTOVÝ MARKETING tvoří významnou část <span className="underline primary">růstových strategií</span>. Něco k němu:</Heading2>
        <Paragraph>Hlavní věc, na kterou je potřeba v marketingu myslet, je <span className="secondary">s kým komunikujeme.</span></Paragraph>
        <Paragraph>Abychom věděli, JAK s ním komunikovat.</Paragraph>
        <Paragraph>K tomu <span className="secondary">potřebujeme znát cílovku.</span></Paragraph>
        <Paragraph>Asi si myslíte: <i>“Hmm, teď mi neříkáte nic nového...”</i></Paragraph>
        <Heading2>Ale stejně o tom mluvím! Proč? Protože <span className="secondary">poznání cílovky může jít do různých hloubek.</span></Heading2>
        <Heading2>A čím jde HLOUBĚJI, tím je LEPŠÍ.</Heading2>
        <Paragraph>Např. když pracujeme pro naše klienty, snažíme se pochopit psychologii jejich zákazníků.</Paragraph>
        <Paragraph>A to myslím celkem <span className="underline"><i>hardcore</i> psychologii.</span></Paragraph>
        <Paragraph><i>Komplexy, skryté touhy, ultimátní motivace, strachy, zlozvyky...</i></Paragraph>
    </div>
};

export default Growth;