import React from "react";
import "./Sometimes.scss";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Paragraph } from "../../UI/Paragraph/Paragraph";

interface SometimesProps {

}

export const Sometimes: React.FC<SometimesProps> = () => {
    return <div className="Sometimes">
        <Heading2>Někdy marketéři mluví o <span className="secondary">psychologických “technikách” a “principech”,</span> které si stejně <span className="secondary">zastřešíme pojmem “MANIPULACE”.</span></Heading2>
        <Paragraph>Ano, marektingové techniky existují. <span className="secondary">Fungují a dokáží zmanipulovat.</span></Paragraph>
        <Paragraph>Jak se k tomu stavět?</Paragraph>
        <Paragraph><span className="primary">Eticky.</span></Paragraph>
        <Paragraph>Pokud víte, že nákup produktu zákazníkovi <i><span className="underline">opravdu</span></i> pomůže, je podle mě v pořádku těchto technik využít.</Paragraph>
        <Paragraph>Pokud se snažíte někoho oškubat, je to špatně.</Paragraph>
        <Paragraph>Řekněme, že k tomu přistupujete eticky...</Paragraph>
        <Paragraph>S pomocí hloubkové psychologie máte s těmito technikami <span className="secondary">nadliskou přesvědčovací moc.</span></Paragraph>
        <Heading2>Dál je potřeba přistupovat k marketingu vědecky.</Heading2>
        <Paragraph>Opět, <span className="secondary">nechceme hypotézy, ale <i>teorie</i>.</span></Paragraph>
        <Paragraph>Chceme vyhodnocovat data, konverzní poměry, využívat webovou analytiku (např. Google Analytics)...</Paragraph>
        <Paragraph><span className="secondary">Je potřeba testovat</span> - testujte, které kampaně fungují, jaké motivy v titulcích fungují.</Paragraph>
        <Paragraph>Testujte i různé nabídky! A jejich nacenění!</Paragraph>
        <Paragraph>K nabídkám zkuste přihodit bonusy.</Paragraph>
        <Paragraph>Zeptejte se vašich zákazníků, jaký bonus by se jim k nákupu hodil!</Paragraph>
        <Heading2>Jde o to, abychom jasně viděli, <span className="secondary">která z nabídek, jak naceněná a jak komunikovaná, má největší <span className="underline secondary">profitabilitu pro váš byznys.</span></span></Heading2>
        <Heading2>Pokud se spolehneme na <i>domněnku</i>, že aktuální nabídka a aktuální nacenění je nejlepší, <span className="secondary">přicházíme o peníze</span>.</Heading2>
        <Heading2>Přemýšlejme tak, že tvoříme cestu.</Heading2>
        <Paragraph>Potenciální nakupující se nějak dostane <span className="secondary">od prvního kontaktu</span> s vaší značkou <span className="secondary">až k pravidelnému využívání</span> vašich služeb - to je nějaká <i><span className="underline">cesta</span></i>.</Paragraph>
        <Paragraph><i>Např.: proklik z reklamy =&gt; stránka ke stažení materiálu zdarma výměnou za e-mail =&gt; e-mailový marketing =&gt; návštěva webináře =&gt; stránka na poptávku služby =&gt; schůzka =&gt; nákup.</i></Paragraph>
        <Paragraph>Jinak se podobným cestám říká <span className="secondary"><i>trychtýř</i></span>. Asi jste ten pojem už slyšeli, dost možná na vás nezachal pozitivní dojem.</Paragraph>
        <Paragraph>Pravdou je, že <span className="primary">koncept trychtýřů je velmi efektivní.</span></Paragraph>
        <Heading2>Cílem vnímání marketingu jako cesty je, že se tak jsme schopní lépe <span className="secondary">vžít do mysli konkrétního potenciálního zákazníka...</span></Heading2>
        <Paragraph>a připravit cestu tak, aby byla co <i><span className="underline">nejpřirozenější, nejpřímější a nejefektivnější.</span></i></Paragraph>
        <Heading2>SHRNUTÍ</Heading2>
        <Paragraph>Samozřejmě by toho mohlo být mnohem víc, ale určitě máte na dnešek i další plány :)</Paragraph>
        <Paragraph>Věci bych shrnul tak, že je potřeba si <span className="secondary">dávat pozor na firmy,</span> které vypadají, že jim na vás záleží, ale není to tak.</Paragraph>
        <Paragraph>Firmy by se měly <span className="secondary">soustředit na své klienty</span> a upřednostňovat jejich potřeby. Ať už v B2B nebo B2C.</Paragraph>
        <Paragraph><span className="secondary">Byznysy tu jsou, protože řeší problémy trhu.</span> Problémy trhu tu nejsou, aby na nich šlo zbohatnout.</Paragraph>
        <Paragraph>Když už se teda rozhodneme soustředit na klienty, <span className="secondary">potřebujeme data.</span></Paragraph>
        <Paragraph>Potřeba dat nám přerůstá do všeho.</Paragraph>
        <Paragraph>Hypotézy jsou fajn, ale je potřeba je podkládat daty a tvořit z nich teorie, jinak jsou všechny snahy o růst ruletou.</Paragraph>
        <Paragraph>Když máme data a teorie, můžeme na nich <span className="secondary">stavět růstové strategie.</span></Paragraph>
        <Paragraph>Růstová strategie má za cíl efektivně dostat firmu tam, kde chceme, aby byla, ať je k tomu potřeba cokoli.</Paragraph>
        <Paragraph>K jejímu vytvoření je mimo jiné potřeba dostatek kreativity i zkušeností a firmy si ji mohou dělat samy s vlastním růstovým týmem nebo jim může pomoci růstová agentura.
            <Paragraph>Velkou částí růstových strategií je zpravidla <span className="secondary">růstový marketing.</span></Paragraph>
            <Paragraph>K němu je třeba<br></br>
                    • pečlivě poznat <span className="secondary">psychografii cílovky</span>,<br></br>
                    •   zaujmout k jeho návrhům a výsledkům striktně <span className="secondary">vědecký, na datech založený postoj</span><br></br>
                    •  a <span className="secondary">vnímat ho z pohledu potenciálního zákazníka,</span> který s ním přichází do styku.</Paragraph>
        </Paragraph>
        <Paragraph><span className="primary">Ti z vás, kterým článek pomohl, jsem rád, že jste četli!</span></Paragraph>
    </div>
};

export default Sometimes;