import React from "react";
import "./Task.scss";
import { Paragraph } from "../../UI/Paragraph/Paragraph";

interface TaskProps {

}

export const Task: React.FC<TaskProps> = () => {
    return <div className="Task">
        <Paragraph><span className="secondary">Když je můj úkol</span> někomu ukázat, že mu nákup devíti-a-půl vrstvého toaletního papíru z ovčí vlny zlepší život, a <span className="secondary">přimět ho si produkt koupit,</span> musím vědět dvě věci:</Paragraph>
        <Paragraph><span className="secondary">1. že a jak mu nákup produktu opravdu pomůže a<br></br>
2. jak mu tento výsledek komunikovat.</span></Paragraph>
        <Paragraph>Mohl bych napsat o benefitech ovčí vlny v toaletním papíru, ale když benefity cílovce nijak neslouží, nemá to cenu.</Paragraph>
        <Paragraph>Nebo můžu psát dlouze a poeticky, ale pokud cílovka nemá čas to číst, o hodnotě produktu se nedozví.</Paragraph>
        <Paragraph>Trochu hloupý příklad, ale asi chápete pointu.</Paragraph>
        <Paragraph><span className="secondary">Poznejte psychologii cílovky do hloubky.</span></Paragraph>
    </div>
};

export default Task;