import React from "react";
import './Thing.scss';
import { Heading1 } from "../../UI/Heading1/Heading1";
import unicorn from '../../assets/unicorn.png';
import { Paragraph } from "../../UI/Paragraph/Paragraph";
import { Heading2 } from "../../UI/Heading2/Heading2";

export const Thing: React.FC<{}> = () => {
    return <div className="Thing">
        <Heading1>VĚC, co by měla být <span className="primary">samozřejmá</span>,
            ale v byznysu je <span className="primary">vzácná</span>...</Heading1>
        <img alt="Unicorn" src={unicorn}></img>
        <Paragraph>Řekli jsme si, že je potřeba se soustředit na toho, kdo od nás nakupuje.</Paragraph>
        <Paragraph>Proč?</Paragraph>
        <Paragraph>No jeden z důvodů je, že mu primárně pomáháme řešit jeho problém.</Paragraph>
        <Paragraph><span className="secondary">Za to platí.</span></Paragraph>
        <Heading2>Jsem poměrně smutný z celého toho konceptu <i><span className="red">“Jdu podnikat jen, abych vydělal...”</span></i></Heading2>
        <Paragraph>A ještě lépe: <i>“Jdu podnikat jen, abych vydělal, a pak už nikdy nemusel pracovat!”</i></Paragraph>
        <Paragraph>...??!</Paragraph>
        <Heading2><span className="primary">Jdu podnikat, protože věřím, že dokážu světu přinést víc, jako podnikatel, A K TOMU si můžu více vydělat, a být tak schopný ... <i>(doplňte si)</i>.</span></Heading2>
        <Paragraph>Jdu podnikat a pracovat i proto, že v oboru, ve kterém podnikám, a ve službě, kterou dělám, vidím smysl!</Paragraph>
        <Paragraph><span className="underline">Každému svému klientovi jsem se snažil přinést maximum a zároveň přinést maximum i jeho klientům.</span></Paragraph>
        <Paragraph>A jelikož děláme byznys i proto, abychom lidem pomohli, měli bychom jim... 🥁🥁🥁 pomáhat!</Paragraph>
        <Paragraph><span className="secondary">Měli bychom být nějak nápomocní i zdarma. Dělat věci navíc.</span></Paragraph>
        <Paragraph>A když už ne kvůli hodnotám, tak už jednoduše kvůli tomu, že naši kupující, jsou... lidi!</Paragraph>
        <Paragraph><span className="secondary">A lidi dokáží ocenit, když jim někdo pomůže.</span></Paragraph>
        <Paragraph><span className="secondary">A tomu, kdo jim pomůže, pak více důvěřují.</span></Paragraph>
        <Paragraph><span className="primary">A od toho, komu důvěřují, nakupují.</span></Paragraph>
        <Heading2>Přirozeně. <i><span className="primary">“Když mi tento člověk pomohl zadarmo, tak jeho placené služby musí stát za to!”</span></i></Heading2>
        <Paragraph>Firmám, kterým jde o pomoc klientele, se jejich dobrá vůle v budoucnu finančně vrátí mnohokrát více.</Paragraph>
        <Paragraph><span className="secondary">Nakonec nejvíce vydělají ti, kteří opravdu pomáhají.</span> Ti, jejichž služby mají opravdu smysl.</Paragraph>
        <Paragraph>Ti, kterým jde <span className="underline"><i>opravdu</i></span> o jejich klienta.</Paragraph>
    </div>
};

export default Thing;